import React from 'react';
import { Link } from 'gatsby';
import Container from './style';

const NotFound: React.FC = () => (
  <Container>
    <div className="container">
      <div className="section-content">
        <div className="topics-content">
          <h1 className="wow fadeInDown">ページが見つかりません</h1>
          <div className="topics-content">
            <p>
              ご指定のページは削除、変更されたか、現在利用できない可能性があります。
            </p>
          </div>
          <div id="return">
            <Link to="/">Homeへ</Link>
          </div>
        </div>
      </div>
      <div className="silhouette-container">
        <span className="silhouette-image" />
      </div>
    </div>
  </Container>
);

export default NotFound;
