import styled from 'styled-components';
import SectionWrapper from '../../styles/SectionWrapper';
import Colors from '../../consts/Colors';
import headerBusiness from '../../assets/header_business.png';
import silhouetteBusiness from '../../assets/silhouette_business.png';

export default styled(SectionWrapper)`
  background-image: linear-gradient(#fff 0, #ececec 100%);
  h1 {
    background: url(${headerBusiness}) no-repeat 0 0;
  }
  div.topics-content {
    font-size: 14px;
    color: ${Colors.textContent};
    margin-top: 50px;
    margin-left: 55px;
    margin-right: 55px;
    p {
      line-height: 1.5em;
    }
    a:visited {
      color: ${Colors.textContent};
    }
    a:link {
      color: ${Colors.textContent};
    }
    #return {
      font-size: 18px;
      text-align: center;
      margin-top: 100px;
    }
  }
  /* マウスオーバーでシルエット画像切り替え */
  .silhouette-image {
    background: url(${silhouetteBusiness}) no-repeat 0 0;
  }
  .silhouette-image:hover {
    background-position: 0 bottom;
  }
`;
